import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '_helpers';
import axios from 'axios';

export { Dashboard };

function Dashboard(props) {
    const { user: authUser } = useSelector(x => x.auth);
    const { users } = useSelector(x => x.users);
    
    const [dashboardData, setDashboardData] = useState([]);
    const [pendingCount, setPendingCount] = useState(0);
    const [signedoffCount, setSignedoffCount] = useState(0);
    const [printedCount, setPrintedCount] = useState(0);
    const [urgentCount, setUrgentCount] = useState(0);
    const [rewordCount, setRewordCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const [todayTotal, setTodayTotal] = useState(0);
    const [todayPending, setTodayPending] = useState(0);
    const [yesterdayTotal, setYesterdayTotal] = useState(0);
    const [yesterdayPending, setYesterdayPending] = useState(0);
    const [thismonthTotal, setThismonthTotal] = useState(0);
    const [thismonthPending, setThismonthPending] = useState(0);

    const [refreshTime, setRefreshTime] = useState('');
    
    useEffect(() => {
        DashboardList();
      }, []);
     
    if(localStorage.getItem('pagename') !=="Dashboard"){
      return null;
    }
    // only show when logged in
    if (!authUser) return null;

    function randomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    function DashboardList() {
        if(localStorage.getItem('pagename') !=="Dashboard"){
          return null;
        }
        if (!authUser) return null;

        const url = `${process.env.REACT_APP_API_URL}/dashboard`;
  
        const user_key = authUser?.user_key;
        const center_key = authUser?.center_key;

        const request_id = randomNumberInRange(1000000, 9000000);
        const token = JSON.parse(localStorage.getItem('user')).token;
        const search_flag = "";
        const config = {
          headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'user_key': user_key.toString(),
            'center_key': center_key.toString(),
            'search_flag': search_flag,
            'request_id': request_id.toString()
          },
        };
    
        try {
          
          axios.get(url, config).then((response) => {
            //console.log(response.data);
            
            //alert(JSON.stringify(response.data));
            
            let updatedValue = {};
            let _pendingCount = 0; let _signedoffCount = 0;
            let _printedCount = 0; let _urgentCount = 0; 
            let _rewordCount = 0; let _totalCount = 0;
  
            let _todayTotal = 0; let _todayPending = 0;
            let _yesterdayTotal = 0; let _yesterdayPending = 0; 
            let _thismonthTotal = 0; let _thismonthPending = 0;

            if(response.data.length>0){
              updatedValue = response.data; 
              //const data = JSON.stringify(response.data);
              //alert(dashboardData);

              if(updatedValue.length>0){
                _pendingCount = updatedValue[0].pending_count; 
                _signedoffCount = updatedValue[0].signedoff_count; 
                _printedCount = updatedValue[0].printed_count; 
                _urgentCount = updatedValue[0].urgent_count; 
                _rewordCount = updatedValue[0].rework_count; 
                _totalCount = updatedValue[0].total_count; 
              }

              for (let i=0,l=updatedValue.length; i<l; i++) {
                _todayTotal+=updatedValue[i].today_total;
                _todayPending+=updatedValue[i].today_pending;
                _yesterdayTotal+=updatedValue[i].yesterday_total;
                _yesterdayPending+=updatedValue[i].yesterday_pending;
                _thismonthTotal+=updatedValue[i].thismonth_total; 
                _thismonthPending+=updatedValue[i].thismonth_pending;
              }
           }

           var d = new Date();
           var hr = d.getHours();
            var min = d.getMinutes();
            if (min < 10) {
                min = '0' + min;
            }
            var ampm = 'AM';
            if( hr > 12 ) {
                hr -= 12;
                ampm = 'PM';
            }
            var _time = hr.toString() + ':' + min + ' ' + ampm;
           setDashboardData(updatedValue); setRefreshTime(_time);
           setPendingCount(_pendingCount); setSignedoffCount(_signedoffCount);
           setPrintedCount(_printedCount); setUrgentCount(_urgentCount);
           setRewordCount(_rewordCount); setTotalCount(_totalCount);
           
           setTodayTotal(_todayTotal); setTodayPending(_todayPending);
           setYesterdayTotal(_yesterdayTotal); setYesterdayPending(_yesterdayPending);
           setThismonthTotal(_thismonthTotal); setThismonthPending(_thismonthPending);

           setTimeout(function() {
            DashboardList();
          }, 60000);
           
          });
        } catch (err) {
          //debugger;
          //alert('error');
            //alert(err.code);
          if (err.code === '404') {
            //setError('Object does not exist.')
            return
          }
        }
    
      }
  
      function sortClick(event, col_name)
      {
          ;
      }  
    function printClick()
    {
        ;
    }

    const closeClick = (event) => {
        history.navigate('/home');
    };

    
    return (
        <>
            <main>
              <div className="container-fluid px-4">
                <div className="row">
                  <div className="col-12 col-lg-10 col-md-10 col-sm-6 mt-2">
                    <h5 className="text-blue font-roboto font-weight700">Dashboard</h5>
                  </div>
                  
                  <div className="col-12 col-lg-2 col-md-2 col-sm-6 mt-2 mb-1">
                    <button type="button" className="btn btn-grey btn-sm btn-block bi" id="btnlastrefresh">
                      Last Refresh: {refreshTime}</button>
                  </div>
                </div>

                <hr className="mt-0" />

                {/* -- Dashboard Start -- */}
                <div className="row">
                  <div className="col-6 col-lg-2 col-md-3 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-header dashboard-head-pending text-light text-center"><small>Pending </small></div>
                      <div className="card-body-dashbaord dashboard-pending text-center height-70">
                        <h3><i className="bi bi-file-earmark-medical-fill icon icon-pending"></i> {pendingCount}</h3>

                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-lg-2 col-md-3 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-header dashboard-head-signedoff text-light text-center"><small>Signed Off </small></div>
                      <div className="card-body-dashbaord dashboard-signedoff text-center height-70">
                        <h3><i className="bi bi-clipboard2-pulse-fill icon icon-signedoff"></i> {signedoffCount}</h3>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-6 col-lg-2 col-md-3 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-header dashboard-head-printed text-light text-center"><small>Printed </small></div>
                      <div className="card-body-dashbaord dashboard-printed text-center height-70">
                        <h3><i className="bi bi-printer-fill icon icon-printed"></i> {printedCount}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-2 col-md-3 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-header dashboard-head-urgent text-light text-center"><small>Urgent </small></div>
                      <div className="card-body-dashbaord dashboard-urgent text-center height-70">
                        <h3><i className="bi bi-heart-pulse-fill icon icon-urgent"></i> {urgentCount}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-2 col-md-3 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-header dashboard-head-rework text-light text-center"><small>Rework </small></div>
                      <div className="card-body-dashbaord dashboard-rework text-center height-70">
                        <h3><i className="bi bi-file-earmark-medical-fill icon icon-rework"></i> {rewordCount}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-6 col-lg-2 col-md-3 col-sm-6 mb-2">
                    <div className="card">
                      <div className="card-header dashboard-head-total text-light text-center"><small>Total </small></div>
                      <div className="card-body-dashbaord dashboard-total text-center height-70">
                        <h3><i className="bi bi-file-earmark-bar-graph icon icon-total"></i> {totalCount}</h3>
                      </div>
                    </div>
                  </div>

                </div>

                {/* -- Grid Data -- */}
                <div className="row">

                  <div className="col-md-12 mb-2">
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active mt-2" id="all-tab-pane" role="tabpanel" aria-labelledby="all-tab"
                        tabindex="0">
                        <div className="card">
                          <div className="card-header bg-gradient-1 text-light">
                            {/* -- Table Heading -- */}
                            <div className="row">
                              <div className="col-4 col-sm-6 col-md-6">
                                <p className="tele-table-dash-heading">Center </p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 text-right">
                                <p className="tele-table-dash-heading">Today </p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                <p className="tele-table-dash-heading">Pending </p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                <p className="tele-table-dash-heading">Yesterday </p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                <p className="tele-table-dash-heading">Pending </p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 text-right">
                                <p className="tele-table-dash-heading">This Month</p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                <p className="tele-table-dash-heading">Pending </p>
                              </div>
                            </div>
                          </div>
                          <div className="card-body tele-table-padding dash-studylist-area">
                            {/* -- tele-table-rowbg1 -- */}

                            {Object.keys(dashboardData).map((data, i) => ( 
                              (dashboardData[i].thismonth_total > 0 ? 
                                <div key={i} className="row studylist">
                                  <div className="col-4 col-sm-6 col-md-6">
                                    {(authUser?.user_type === 'D' ? 
                                            <p className="tele-table-dash-text">{dashboardData[i].center_name}</p>
                                          : <p className="tele-table-dash-text">{dashboardData[i].center_id} : {dashboardData[i].center_name}</p>
                                    )}
                                  </div>
                                  <div className="col-4 col-sm-1 col-md-1 text-right">
                                    <p className="tele-table-dash-text">{dashboardData[i].today_total}</p>
                                  </div>
                                  <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                    <p className="tele-table-dash-text">{dashboardData[i].today_pending}
                                    </p>
                                  </div>
                                  <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                    <p className="tele-table-dash-text">{dashboardData[i].yesterday_total}</p>
                                  </div>
                                  <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                    <p className="tele-table-dash-text">{dashboardData[i].yesterday_pending}</p>
                                  </div>
                                  <div className="col-4 col-sm-1 col-md-1 text-right">
                                    <p className="tele-table-dash-text">{dashboardData[i].thismonth_total}</p>
                                  </div>
                                  <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                    <p className="tele-table-dash-text">{dashboardData[i].thismonth_pending}</p>
                                  </div>
                                </div>
                                : <span></span>
                              )
                                  

                            ))}
                          </div>

                          <div className="card-header bg-gradient-1 text-light">
                            {/* -- Table Footer Heading -- */}
                            <div className="row">
                              <div className="col-4 col-sm-6 col-md-6">
                                <p className="tele-table-dash-heading">Total </p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 text-right">
                                <p className="tele-table-dash-heading">{todayTotal} </p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                <p className="tele-table-dash-heading">{todayPending} </p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                <p className="tele-table-dash-heading">{yesterdayTotal} </p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                <p className="tele-table-dash-heading">{yesterdayPending} </p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 text-right">
                                <p className="tele-table-dash-heading">{thismonthTotal}</p>
                              </div>
                              <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block text-right">
                                <p className="tele-table-dash-heading">{thismonthPending} </p>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>


                  </div>
                </div>

              </div>
            </main>

            {users.loading && <div className="spinner-border spinner-border-sm"></div>}
            {users.error && <div className="text-danger">Error loading users: {users.error.message}</div>}

        </>
    );
}
