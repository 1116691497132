import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '_helpers';
import axios from 'axios';

export { Invoice };

var search_flag = 'today';
var search_datefrom = '';
var search_dateto = '';
var search_status = 'A';
var search_filter = 'D';
var search_modality = '';

function Invoice() {
    const { user: authUser } = useSelector(x => x.auth);
    const { users } = useSelector(x => x.users);
    
    const [invoiceData, setInvoiceData] = useState([]);
    const [totalPatients, setTotalPatients] = useState(0);
    const [totalReports, setTotalReports] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [totalEstimate, setTotalEstimate] = useState(0);

    let displayNone = {
      display: "none"
    };

    useEffect(() => {
        search_flag = 'today';
        search_datefrom = '';
        search_dateto = '';
        search_status = 'A';
        search_filter = 'D';
        search_modality = '';

        invoiceList('SEARCH');
      }, []);

      
    // only show when logged in
    if (!authUser) return null;

    const handleSearchChange = (event) => {
        var col_name = event.target.id;
        var col_value = event.target.value; 
          if(col_value===''){
            col_value = '';
          }
          else{
            var value_split = col_value.split('-');
              col_value = value_split[2] + '-' + value_split[1] + '-' + value_split[0];
          }
        if(col_name === 'txtfromdate'){ search_datefrom = col_value; }
        if(col_name === 'txttodate'){ search_dateto = col_value; }
    };
    function randomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
    function invoiceList(action_tag) {
        if (!authUser) return null;
  
        const user_key = authUser?.user_key;
        const center_key = authUser?.center_key;

        const request_id = randomNumberInRange(1000000, 9000000);
        const token = JSON.parse(localStorage.getItem('user')).token;

        let url = `${process.env.REACT_APP_API_URL}/invoice`;
        /*if(authUser?.user_type === 'A' && (search_flag === 'thismonth' || search_flag === 'prevmonth'))
        {
            url = `${process.env.REACT_APP_API_URL_EC2}/invoice`;
        }*/

        const config = {
          headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'user_key': user_key.toString(),
            'center_key': center_key.toString(),
            'action_tag': action_tag,
            'search_flag': search_flag,
            'search_datefrom': search_datefrom,
            'search_dateto': search_dateto,
            'search_status': search_status,
            'search_filter': search_modality, //search_filter
            'search_modality': search_modality,
            'request_id': request_id.toString()
          },
        };
    
        try {
          
            ProcessStatus(true, false, '');

          axios.get(url, config).then((response) => {
            //console.log(response.data);
            
            //alert(JSON.stringify(response.data));

            setTotalPatients(0);
            setTotalReports(0);
            setTotalFiles(0);
            setTotalEstimate(0);

            var record_exist = false;
            let updatedValue = {};
            if(response.data.length>0){
              record_exist = true;
              updatedValue = response.data; 

              setTotalPatients(updatedValue[0].total_patients);
              setTotalReports(updatedValue[0].total_reports);
              setTotalFiles(updatedValue[0].total_files);
              setTotalEstimate(updatedValue[0].total_estimate);
              //const data = JSON.stringify(response.data);
              //alert(invoiceData);
           }

           ProcessStatus(false, record_exist, '');

           setInvoiceData(updatedValue);
          });
        } catch (err) {
            ProcessStatus(false, false, '');
          //debugger;
          //alert('error');
            //alert(err.code);
          if (err.code === '404') {
            //setError('Object does not exist.')
            return
          }
        }
    
      }
  
      function ProcessStatus(buttonDisabled, record_exist, filter_col_value) {
        if(buttonDisabled){
          document.getElementById("divLoading").style.display = '';
          document.getElementById("divLoading").innerHTML = 'Processing...';
        }
        else
        {
          document.getElementById("divLoading").innerHTML = '';
          document.getElementById("divLoading").style.display = 'none';
  
          if(record_exist)
           {
              document.getElementById("divStatus").innerHTML = '';
              document.getElementById("divStatus").style.display = 'none';
           }
           else{
              document.getElementById("divStatus").style.display = '';
              if(filter_col_value===''){
                document.getElementById("divStatus").innerHTML = 'No Record Found!';
              }
              else{
                document.getElementById("divStatus").innerHTML = 'Search value '+filter_col_value+' does not found any record.';
              }
           }
        }
      }
  
    const applySearch = (event, param) => {
        event.preventDefault();

        var objddlSelection = document.getElementById("ddlSelection");
        search_flag = objddlSelection.options[objddlSelection.selectedIndex].value;

        search_datefrom = document.getElementById("txtfromdate").value;
        search_dateto = document.getElementById("txttodate").value;

        var objddlfilterStatus = document.getElementById("ddlfilterStatus");
        search_status = objddlfilterStatus.options[objddlfilterStatus.selectedIndex].value;

        var objddlfilterType = document.getElementById("ddlfilterType");
        search_filter = objddlfilterType.options[objddlfilterType.selectedIndex].value;

        var objddlfilterModality = document.getElementById("ddlfilterModality");
        search_modality = objddlfilterModality.options[objddlfilterModality.selectedIndex].value;

        if(search_flag === 'datewise')
        {
            if(search_datefrom===''){
                alert('Enter from date.'); return false;
            }
            if(search_dateto===''){
                alert('Enter to date.'); return false;
            }
        }

        invoiceList('SEARCH');
    }
    function sortClick(event, col_name)
    {
          ;
      }  
    function printClick()
    {
        if(invoiceData.length === 0){
            alert('No record found.');
            return false;
          }
      const template = getReportContent();
      var WinPrint = window.open('', '', 'width=850,height=600');
      WinPrint.document.write(template);
      WinPrint.document.close();
      WinPrint.focus();

      setTimeout(function() {
          WinPrint.print();
          WinPrint.close();
      }, 600); 
    }

    const getReportContent = () =>
    {
      var topMarginHeader = '150';
      var bottomMarginFooter = '120';
      var topMarginBodyArea = '300';
      var bottomMarginBodyArea = '300';

      //topMarginHeader = '100'; bottomMarginFooter = '100'; topMarginBodyArea = '250'; bottomMarginBodyArea = '250';
      //topMarginHeader = '80'; bottomMarginFooter = '80'; topMarginBodyArea = '230'; bottomMarginBodyArea = '230';

      var index_hdr = 0;
      var title = 'Invoice Inquiry'; 
      if(authUser?.user_type === 'U') {
        title += `-` + invoiceData[index_hdr].center_name + `-` + invoiceData[index_hdr].center_id;
      }

      let displayRate = true;
      if(authUser?.user_type === 'U' 
        || (authUser?.user_type === 'A' && authUser?.user_key !== 1)) {
            displayRate = false;
      }
      
      var template_head = `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>`+title+`</title>
          <link href="css/invoice_template.css" rel="stylesheet" />

          <!-- Google Font -->
          <link rel="preconnect" href="https://fonts.googleapis.com">
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap" rel="stylesheet">
      </head>
      <body>
        <div class="container">
        
        <table id="report">
            <thead class="report-header">
    `;

    var template_hdr_row1 = `<tr>
                                <th colspan="5">

                                <div class="invoice-header">
                                    <div class="invoice-header-left">
                                        <img src="img/mytsoft-logo.png" width="50px" height="43px" />
                                        <h4>MYT<span>Soft</span></h4>
                                        <p class="upper">WELCOME TO OUR ONLINE RADIOLOGY SERVICES</p>
                                    </div>
                                    <div class="invoice-header-right">
                                        <h2>INVOICE</h2>
                                        <p><b>Invoice Number:</b> SU505/DR1216/010 <br>
                                            <b>Date:</b> 10/07/2023 <br>
                                            <b>Invoice For Month:</b> Jun, 2023
                                        </p>
                                    </div>
                                </div>
                                <div class="invoice-recipient">
                                    <h4 class="primary-bg">Pay to </h4>
                                    <h3>Dr. Diptendu Kumar Biswas</h3>
                                    <p>Email: quickonmedrad@gmail.com <br>
                                        Mobile: 9330157724 <br>
                                        Address: National Park, North 24 Parganas</p>
                                </div>

                                </th>
                            </tr>`;

        template_hdr_row1 = `<tr>
                            <th colspan="8">
                            <div class="invoice-header">
                                <div class="invoice-header-left">
                                    <h4>Invoice Inquiry</h4>
                                </div>
                            </div>
                            </th>
                        </tr>`;

        var template_hdr_row2 = `<tr>
                                <th style="width:20px;" class="text-center">Srl#</th>
                                <th style="width:80px;" >Date</th>
                                <th style="width:250px;">Patient</th>
                                <th style="width:50px;" >Reports</th>
                                <th style="width:300px;">Study</th>
                                <th style="width:25px;" >Files</th>`;
        if(displayRate) {
           template_hdr_row2 +=`<th style="width:25px;">Unit</th>
                                <th style="width:50px;" class="text-right">Estimate</th>`;
        }
        template_hdr_row2 +=`</tr>`;

    var patient_key_old = 0; var srl_no = 0;
    var template_body = `</thead> <tbody class="report-body">`;
    
    for(var index=0;index<invoiceData.length;index++){
        if(patient_key_old === invoiceData[index].patient_key)
        {
            template_body += `
            <tr>
                <td class="text-center"></td>
                <td></td>
                <td></td>
                <td></td>`;
        }
        else
        {
            srl_no++;
            template_body += `
            <tr>
                <td class="text-center">`+srl_no+`</td>
                <td>`+invoiceData[index].regn_date+`</td>
                <td>`+invoiceData[index].patient_id +` - `+ invoiceData[index].patient_name +` `+ invoiceData[index].patient_age +`/`+ invoiceData[index].patient_sex +`</td>
                <td>`+invoiceData[index].report_count+`</td>`;
        }

        template_body += 
        `   <td>`+invoiceData[index].service+`</td>
            <td>`+invoiceData[index].image_group_count+`</td>`;

        if(displayRate) {
            template_body +=
            `<td>`+invoiceData[index].total_amount +`x 1</td>
            <td class="text-right">`+invoiceData[index].total_amount+`</td>`;
        }
        template_body += `</tr>`;

        patient_key_old = invoiceData[index].patient_key;
    }
    template_body += `</tbody></table>`;

    var template_footer = `
    <footer class="divFooter">
        <div class="row">
            <div class="col-12">
                <div class="footsign">
                    For further queries, please call us / e-mail us at contact@mytsofts.com
                </div>
            </div>
        </div>
    </footer>
    </div>
    </body>

    </html>`;
    
      const template = template_head + template_hdr_row1 + template_hdr_row2 + template_body+template_footer;
      return template;
    }

    
    function showFilterDiv(event) {
      var objddlSelection = document.getElementById("ddlSelection");
      const filterSelection = objddlSelection.options[objddlSelection.selectedIndex].value;      
      document.getElementById('divDateRangeFilter').style.display = filterSelection === 'datewise' ? 'block' : 'none';
  }

    const closeClick = (event) => {
        history.navigate('/home');
    };
    
    return (
        <>
            <main>
                <div className="container-fluid px-4">

                    <div className="row">
                        <div className="col-12 col-lg-10 col-md-10 col-sm-10 mt-2">
                            <h5 className="text-blue font-roboto font-weight700">Invoice Inquiry</h5>
                        </div>
                        <div className="col-6 col-lg-1 col-md-1 col-sm-1 mt-2 mb-1">
                            <button type="button" className="btn btn-secondary btn-sm btn-block text-light bi bi-save2" id="btnPrint" onClick={printClick}> PRINT</button>
                        </div>
                        <div className="col-6 col-lg-1 col-md-1 col-sm-1 mt-2 mb-1">
                            <button type="button" className="btn btn-dark btn-sm btn-block text-light bi bi-x-circle" id="btnClose" onClick={closeClick}> CLOSE</button>
                        </div>
                    </div>

                    <hr className="mt-0" />

                    {/*<!-- Accordion Reprot Generate -->*/}
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                                            aria-controls="panelsStayOpen-collapseOne">
                                            <i className="bi bi-file-earmark-medical iconcolr-w"></i>&nbsp; Quick Selection Report
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show"
                                        aria-labelledby="panelsStayOpen-headingOne">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-4 col-lg-2 col-md-2 col-sm-12 mt-1">
                                                    <select className="form-select form-select-sm" id="ddlSelection" name="ddlSelection"
                                                      onChange={event => showFilterDiv(event) }>
                                                        <option value="today">Today</option>
                                                        <option value="yesterday">Yesterday</option>
                                                        <option value="last7days">Last 7 Days</option>
                                                        <option value="thismonth">This Month</option>
                                                        <option value="prevmonth">Previous Month</option>
                                                        <option value="datewise">Date Wise</option>
                                                    </select>
                                                </div>
                                                <div className="col-4 col-lg-4 col-md-4 col-sm-12 mt-1" id="divDateRangeFilter" style={displayNone}>
                                                    <div className="row">
                                                        <div className="col-6 col-lg-6 col-md-6 col-sm-12 ps-2 pe-2">
                                                            <div className="input-group input-group-sm">
                                                                <div className="input-group-prepend"><span id="basic-addon1" className="input-group-text">
                                                                  <small>From Date</small></span>
                                                                </div> 
                                                                <input type="date" placeholder="From Date" id="txtfromdate" name="txtfromdate" required="required" className="form-control form-control-sm tele-table-invoicearea" onChange={handleSearchChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-lg-6 col-md-6 col-sm-12 ps-2 pe-2">
                                                            <div className="input-group input-group-sm">
                                                                <div className="input-group-prepend"><span id="basic-addon1" className="input-group-text">
                                                                  <small>To Date</small></span>
                                                                </div> 
                                                                <input type="date" placeholder="To Date" id="txttodate" name="txttodate" required="required" className="form-control form-control-sm tele-table-invoicearea" onChange={handleSearchChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4 col-lg-2 col-md-2 col-sm-12 mt-1" style={displayNone}>
                                                    <select className="form-select form-select-sm" id="ddlfilterType" name="ddlfilterType">
                                                        <option value="S">Summary</option>
                                                        <option value="D">Detail</option>
                                                    </select>
                                                </div>
                                                <div className="col-4 col-lg-2 col-md-2 col-sm-12 mt-1">
                                                    <select className="form-select form-select-sm" id="ddlfilterModality" name="ddlfilterModality">
                                                        <option value="">All Modality</option>
                                                        <option value="DX">X-Ray</option>
                                                        <option value="CT">CT</option>
                                                    </select>
                                                </div>
                                                <div className="col-4 col-lg-2 col-md-2 col-sm-12 mt-1">
                                                    <select className="form-select form-select-sm" id="ddlfilterStatus" name="ddlfilterStatus">
                                                        <option value="A">All Status</option>
                                                        <option value="P">Pending</option>
                                                        <option value="S">Signed Off</option>
                                                        <option value="U">Urgent</option>
                                                        <option value="F">Printed</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-lg-2 col-md-2 col-sm-12 mt-1">
                                                    <button type="button" className="btn btn-dark btn-sm btn-block text-light bi bi-play-fill" id="btnApply" onClick={event => applySearch(event, 'Search')}> APPLY</button>
                                                </div>
                                                <div className="col-6 col-lg-2 col-md-2 col-sm-12 mt-1">
                                                    <div className="nav-item" id="divLoading"> processing...</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<!-- End of Accordion Reprot Generate -->*/}


                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <div className="tab-content" id="tblInvoiceDtls">
                                <div className="tab-pane fade show active mt-2" id="all-tab-pane" role="tabpanel"
                                    aria-labelledby="all-tab" tabindex="0">
                                    <div className="card">
                                        <div className="card-header bg-gradient-1 text-light">
                                            {/*<!-- Table Heading -->*/}
                                            <div className="row">
                                                <div className="col-4 col-sm-1 col-md-1">
                                                    <p className="tele-table-heading">Date </p>
                                                </div>
                                                <div className="col-4 col-sm-2 col-md-3">
                                                    <p className="tele-table-heading">Patient </p>
                                                </div>
                                                <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                    <p className="tele-table-heading">Status </p>
                                                </div>
                                                <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                    <p className="tele-table-heading">Reports </p>
                                                </div>
                                                <div className="col-4 col-sm-2 col-md-3 d-none d-sm-block">
                                                    <p className="tele-table-heading">Study </p>
                                                </div>
                                                <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                    <p className="tele-table-heading">Files </p>
                                                </div>
                                                {(authUser?.user_type === 'U' || (authUser?.user_type === 'A' && authUser?.user_key !== 1) ? 
                                                        <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block" style={displayNone}></div>
                                                    :   
                                                        <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                            <p className="tele-table-heading">Unit</p>
                                                        </div>
                                                )}
                                                {(authUser?.user_type === 'U' || (authUser?.user_type === 'A' && authUser?.user_key !== 1) ? 
                                                        <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block" style={displayNone}></div>
                                                    :   
                                                        <div className="col-4 col-sm-2 col-md-1 text-right">
                                                            <p className="tele-table-heading">Estimate </p>
                                                        </div>
                                                )}
                                                
                                            </div>
                                        </div>
                                        <div className="card-body tele-table-padding invoice-studylist-area">
                                            {/*<!-- tele-table-rowbg1 -->*/}

                                            <div className="row studylist" id="divStatus">
                                            </div>

                                            {Object.keys(invoiceData).map((data, i) => (
                                                <div key={i} className="row studylist">
                                                    <div className="col-4 col-sm-1 col-md-1">
                                                        {(invoiceData[i].file_seq_no === 1 ?
                                                            <span>{invoiceData[i].regn_date}</span>
                                                            : <span></span>
                                                        )}
                                                    </div>
                                                    <div className="col-4 col-sm-2 col-md-3">
                                                        {(invoiceData[i].file_seq_no === 1 ?
                                                            <span>{invoiceData[i].patient_id} - {invoiceData[i].patient_name} {invoiceData[i].patient_age}/{invoiceData[i].patient_sex}</span>
                                                            : <span></span>
                                                        )}
                                                    </div>
                                                    <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                        {(invoiceData[i].file_seq_no === 1 ?
                                                            <span>{invoiceData[i].report_status_desc}</span>
                                                            : <span></span>
                                                        )}
                                                    </div>
                                                    <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                        {(invoiceData[i].file_seq_no === 1 ?
                                                            <span>{invoiceData[i].report_count} (Files#{invoiceData[i].image_total_count})</span>
                                                            : <span></span>
                                                        )}
                                                    </div>
                                                    <div className="col-4 col-sm-2 col-md-3 d-none d-sm-block">
                                                        {invoiceData[i].service}
                                                    </div>
                                                    <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                        {invoiceData[i].image_group_count}
                                                    </div>
                                                    {(authUser?.user_type === 'U' || (authUser?.user_type === 'A' && authUser?.user_key !== 1) ? 
                                                            <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block" style={displayNone}></div>
                                                        :   
                                                        <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block"> {invoiceData[i].total_amount} x 1</div>
                                                    )}
                                                    
                                                    {(authUser?.user_type === 'U' || (authUser?.user_type === 'A' && authUser?.user_key !== 1) ? 
                                                            <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block" style={displayNone}></div>
                                                        :   
                                                        <div className="col-4 col-sm-2 col-md-1 text-right"> {invoiceData[i].total_amount}</div>
                                                    )}
                                                    
                                                </div>
                                            ))}

                                        </div>

                                        <div className="card-header bg-gradient-1 text-light">
                                            {/*<!-- Table Footer -->*/}
                                            <div className="row">
                                                <div className="col-4 col-sm-1 col-md-1">
                                                    <p className="tele-table-dash-heading">Total</p>
                                                </div>
                                                <div className="col-4 col-sm-2 col-md-3">
                                                    <p className="tele-table-dash-heading">{totalPatients}</p>
                                                </div>
                                                <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                    <p className="tele-table-dash-heading"> </p>
                                                </div>
                                                <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                    <p className="tele-table-dash-heading">{totalReports} </p>
                                                </div>
                                                <div className="col-4 col-sm-2 col-md-3 d-none d-sm-block">
                                                    <p className="tele-table-dash-heading"> </p>
                                                </div>
                                                <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                    <p className="tele-table-dash-heading">{totalFiles} </p>
                                                </div>
                                                {(authUser?.user_type === 'U' || (authUser?.user_type === 'A' && authUser?.user_key !== 1) ? 
                                                        <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block" style={displayNone}></div>
                                                    :   
                                                    <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block">
                                                        <p className="tele-table-dash-heading"></p>
                                                    </div>
                                                )}
                                                
                                                {(authUser?.user_type === 'U' || (authUser?.user_type === 'A' && authUser?.user_key !== 1) ? 
                                                        <div className="col-4 col-sm-1 col-md-1 d-none d-sm-block" style={displayNone}></div>
                                                    :   
                                                    <div className="col-4 col-sm-2 col-md-1 text-right">
                                                        <p className="tele-table-dash-heading">{totalEstimate} </p>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </main>

            {users.loading && <div className="spinner-border spinner-border-sm"></div>}
            {users.error && <div className="text-danger">Error loading users: {users.error.message}</div>}

        </>
    );
}
