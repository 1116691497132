import { FooterLink } from "react-router-dom";
import { useSelector } from "react-redux";

export { Footer };

function Footer() {
    const authUser = useSelector((x) => x.auth.user);

    // only show Menu when logged in
    if (!authUser) return null;

    return (
        <footer class="py-2 tele-footer-bg mt-auto">
            <div class="container-fluid px-4">
                <div class="d-flex align-items-center justify-content-between small">
                    <div>Copyright &copy; MYTSOFT 2022-2024</div>
                    <div>
                        <a href=" #">Privacy Policy</a>
                        &nbsp;|&nbsp;
                        <a href=" #">Terms &amp; Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
