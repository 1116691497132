import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';

export { Menu };

function Menu(prop) {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());
    
    // only show Menu when logged in
    if (!authUser) return null;

    return (
        <>
            <nav className="sb-sidenav accordion tele-sidenav-dark" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
                    <div className="nav">
                        <NavLink to="/" className="nav-link" onClick={prop.clickMenu} >
                            Home
                        </NavLink>

                        <NavLink to="/patient" className="nav-link" onClick={prop.clickMenu} > Upload Reports</NavLink>
                        
                        <NavLink to="/invoice" className="nav-link" onClick={prop.clickMenu} >
                            Invoice Inquiry
                        </NavLink>
                        
                        <NavLink to="/dashboard" className="nav-link" onClick={prop.clickMenu} >
                            Dashboard
                        </NavLink>
{/* 

                        <NavLink to="/imageviewer" className="nav-link">
                            Image Viewer
                        </NavLink>

                        <NavLink to="/center" className="nav-link">
                            Center Master
                        </NavLink>

                        <NavLink to="/rate" className="nav-link">
                            Rate Master
                        </NavLink>

                        <a className="nav-link" href=" #">
                            <div className="sb-nav-link-icon"><i className="fa fa-medkit" aria-hidden="true"></i></div>
                            Main Menu
                        </a>
                        <a className="nav-link collapsed" href=" #" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                            <div className="sb-nav-link-icon"><i className="bi bi-clipboard2-pulse-fill" aria-hidden="true"></i></div>
                            Reports
                            <div className="sb-sidenav-collapse-arrow"><i className="fa fa-angle-down" aria-hidden="true"></i></div>
                        </a>
                        <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                            <nav className="sb-sidenav-menu-nested nav">
                                <a className="nav-link" href=" #"><small><i className="bi bi-bookmarks-fill"></i> Report 1</small></a>
                                <a className="nav-link" href=" #"><small><i className="bi bi-bookmarks-fill"></i> Report 2</small></a>
                                <a className="nav-link" href=" #"><small><i className="bi bi-bookmarks-fill"></i> Report 3</small></a>
                            </nav>
                        </div>
*/}                     
                        <a className="nav-link collapsed" href="#!" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts" onClick={logout}>
                            <div className="sb-nav-link-icon"><i class="bi bi-box-arrow-right font25"></i></div> 
                            Logout
                        </a>
                    </div>
                </div>
            </nav>


        </>
    );
}