import { useSelector } from 'react-redux';

export { Profile };

function Profile() {
   const { user: authUser } = useSelector(x => x.auth);
   const { users } = useSelector(x => x.users);

   // only show when logged in
   if (!authUser) return null;

   return (
      <>
         <main>
            <div className="container-fluid px-4">
               <div className="row">
                  <div className="col-lg-8 col-md-4 mt-3">
                     <h5 className="text-blue font-roboto font-weight700"><small>Good Morning! Dr. Ambarish Kundu </small></h5>
                  </div>
                  <div className="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" className="btn btn-danger btn-sm btn-block text-light bi bi-save2" > Save</button>
                  </div>
                  <div className="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" className="btn btn-tele btn-sm btn-block text-light bi bi-save2" > Update</button>
                  </div>
                  <div className="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" className="btn btn-secondary btn-sm btn-block text-light bi bi-trash3-fill" > Delete</button>
                  </div>
                  <div className="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" className="btn btn-dark btn-sm btn-block text-light bi bi-x-circle" > Close</button>
                  </div>

               </div>

               <hr className="mt-0" />

               <div className="row">
                  <div className="col-lg-3 col-md-12 mb-4">
                     <div className="card">
                        <div className="card-header bg-gradient-1 text-light"><small>Profile </small></div>
                        <div className="card-body">
                           <div className="row mb-3">
                              <div className="col-12 text-center">
                                 <img src="img/img_profile.png" alt="user" className="user_profile" />
                              </div>
                           </div>
                           <div className="table-responsive">
                              <table className="table table-borderless mb-0 tele-table-text">
                                 <tbody>
                                    <tr>
                                       <th className="ps-0" scope="row">User ID :</th>
                                       <td className="text-muted">UID0023</td>
                                    </tr>
                                    <tr>
                                       <th className="ps-0" scope="row">Full Name :</th>
                                       <td className="text-muted">Dr. Ambarish Kundu</td>
                                    </tr>
                                    <tr>
                                       <th className="ps-0" scope="row">Mobile :</th>
                                       <td className="text-muted">+(91) 987-658-9743</td>
                                    </tr>
                                    <tr>
                                       <th className="ps-0" scope="row">E-mail :</th>
                                       <td className="text-muted">drambarish@apollohospitals.com</td>
                                    </tr>
                                    <tr>
                                       <th className="ps-0" scope="row">Location :</th>
                                       <td className="text-muted">California, United States
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-9 col-md-12 mb-4">
                     <div className="card">
                        <div className="card-body">
                           <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"><small>Create User </small></button>
                              </li>
                              <li className="nav-item" role="presentation">
                                 <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"><small>Show All Users </small></button>
                              </li>
                           </ul>
                           <div className="tab-content" id="myTabContent">
                              <div className="tab-pane fade show active mt-2" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">

                                 <div className="row p-2">
                                    <div className="col-12 col-lg-8 col-md-12 col-8">
                                       <div className="row pe-2 ps-2">
                                          <div className="col-4 col-lg-2 col-md-12">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small><i className="fa fa-id-badge" aria-hidden="true"></i> User ID: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12">
                                             <div className="mb-2">
                                                <input type="text" className="form-control" placeholder="User ID" id="txtUserID" aria-describedby="" />
                                             </div>
                                          </div>
                                          <div className="col-4 col-lg-2 col-md-12">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small><i className="fa fa-user" aria-hidden="true"></i> User Name: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12">
                                             <div className="mb-2">
                                                <input type="text" className="form-control" placeholder="User Name" id="txtUserName" aria-describedby="" />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row pe-2 ps-2">
                                          <div className="col-4 col-lg-2 col-md-12 col-2">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small><i className="fa fa-phone" aria-hidden="true"></i> Phone No.: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12 col-4">
                                             <div className="mb-2">
                                                <input type="email" className="form-control" placeholder="Phone No." id="txtPhone" aria-describedby="" />
                                             </div>
                                          </div>
                                          <div className="col-4 col-lg-2 col-md-12 col-2">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small><i className="fa fa-envelope-o" aria-hidden="true"></i> Email: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12 col-4">
                                             <div className="mb-2">
                                                <input type="email" className="form-control" placeholder="Email" id="txtEmail" aria-describedby="" />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row pe-2 ps-2">
                                          <div className="col-4 col-lg-2 col-md-12">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small><i className="fa fa-key" aria-hidden="true"></i> User Pass: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12">
                                             <div className="mb-2">
                                                <input type="password" className="form-control" id="txtUserPassword" placeholder="XXXX" />
                                             </div>
                                          </div>
                                          <div className="col-4 col-lg-2 col-md-12">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small><i className="fa fa-key" aria-hidden="true"></i> Temp Pass: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12">
                                             <div className="mb-2">
                                                <input type="password" className="form-control" id="txtTempUserPassword" placeholder="XXXX" />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row pe-2 ps-2">
                                          <div className="col-4 col-lg-2 col-md-12">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small><i className="fa fa-th-list" aria-hidden="true"></i> User Type: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12">
                                             <div className="mb-2">
                                                <select className="form-select form-select-sm" aria-label=".form-select-sm example">
                                                   <option selected>Select User Type</option>
                                                   <option value="A">Admin</option>
                                                   <option value="D">Doctor</option>
                                                   <option value="U">User</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div className="col-4 col-lg-2 col-md-12">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small>Commission: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12">
                                             <div className="mb-2">
                                                <input type="text" className="form-control" placeholder="Commission (%)" aria-describedby="" />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row pe-2 ps-2">
                                          <div className="col-12 col-lg-6 col-md-12">
                                             <div className="mb-1 form-check">
                                                <input type="checkbox" className="form-check-input" id="commissionAppl" />
                                                <label className="form-check-label" htmlFor="commissionAppl"><small>Commission Applicable </small></label>
                                             </div>
                                          </div>
                                          <div className="col-4 col-lg-2 col-md-12">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small>Doctor Rate: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12">
                                             <div className="mb-2">
                                                <input type="text" className="form-control" placeholder="Doctor Rate (%)"  aria-describedby="" />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row pe-2 ps-2">
                                          <div className="col-4 col-lg-2 col-md-12">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small><i className="fa fa-shield" aria-hidden="true"></i> Qualification: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12">
                                             <div className="mb-2">
                                                <textarea className="form-control"  placeholder="Description" rows="3"></textarea>
                                             </div>
                                          </div>
                                          <div className="col-4 col-lg-2 col-md-12">
                                             <div className="mb-2">
                                                <label htmlFor="" className="mb-1"><small><i className="fa fa-shield" aria-hidden="true"></i> Designation: </small></label>
                                             </div>
                                          </div>
                                          <div className="col-8 col-lg-4 col-md-12">
                                             <div className="mb-2">
                                                <textarea className="form-control"  placeholder="Description" rows="3"></textarea>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row pe-2 ps-2">

                                          <div className="col-lg-6 col-md-12 col-6">
                                             <div className="mb-1 form-check">
                                                <input type="checkbox" className="form-check-input" id="frozenCheck" />
                                                <label className="form-check-label" htmlFor="frozenCheck"><small>Frozen User </small></label>
                                             </div>
                                          </div>
                                          <div className="col-lg-6 col-md-12 col-6">
                                             <div className="mb-1 form-check">
                                                <input type="checkbox" className="form-check-input" id="approveCheck" />
                                                <label className="form-check-label" htmlFor="approveCheck"><small>Approve User </small></label>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-12 col-4">
                                       <div className="col-lg-12 col-md-12 col-12">
                                          <div className="row pe-2 ps-2">
                                             <div className="col-5">
                                                <div className="mb-3 form-check ps-0">
                                                   <div id="display_profile"></div>
                                                   <div className="form-text"><small>Uploaded Profile </small></div>
                                                </div>
                                             </div>
                                             <div className="col-7">
                                                <div className="mb-3 form-check ps-1">
                                                   <div id="display_sign"></div>
                                                   <div className="form-text"><small>Uploaded Signature </small></div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="row ps-2 pe-2">
                                             <div className="col-6">
                                                <div className="mb-3">
                                                   <label htmlFor="" className="mb-1">Upload Profile</label>
                                                   <div className="input-group">
                                                      <input type="file" className="form-control form-control-sm" placeholder="Uplaod Profile Photograph" id="profile_input" aria-describedby="" />

                                                   </div>
                                                   <div className="form-text">.png/.jpg/.jpeg files are allowed</div>
                                                </div>
                                             </div>
                                             <div className="col-6">
                                                <div className="mb-3">
                                                   <label htmlFor="" className="mb-1">Upload Sign</label>
                                                   <div className="input-group">
                                                      <input type="file" className="form-control form-control-sm" placeholder="Uplaod Signature" id="sign_input" aria-describedby="" />

                                                   </div>
                                                   <div className="form-text">.png/.jpg/.jpeg files are allowed</div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="row ps-2 pe-2">
                                             <div className="col-6">
                                                <div className="mb-3">
                                                   <button type="button" className="btn btn-secondary btn-sm btn-block text-light bi bi-eraser-fill" onclick="removeProfile()"> Delete Profile</button>
                                                </div>
                                             </div>
                                             <div className="col-6">
                                                <div className="mb-3">
                                                   <button type="button" className="btn btn-secondary btn-sm btn-block text-light bi bi-eraser-fill" onclick="removeSign()"> Delete Sign</button>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>


                              </div>
                              <div className="tab-pane fade mt-2" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">

                                 <div className="card">
                                    <div className="input-group input-group-sm mb-2"><span className="input-group-addon"><i className="bi bi-search"></i></span> <input id="search" name="search" placeholder="Search..." className="form-control" /></div>
                                    <div className="card-header bg-gradient-1 text-light">
                                       {/*<!-- Table Heading -->*/}
                                       <div className="row">
                                          <div className="col-3 col-sm-2 col-md-2">
                                             <p className="tele-table-heading"><small> User Id </small></p>
                                          </div>
                                          <div className="col-3 col-sm-3 col-md-3">
                                             <p className="tele-table-heading"><small>User Name </small></p>
                                          </div>

                                          <div className="col-3 col-sm-2 col-md-2">
                                             <p className="tele-table-heading"><small>Frozen </small></p>
                                          </div>
                                          <div className="col-3 col-sm-2 col-md-2">
                                             <p className="tele-table-heading"><small>Approve </small></p>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="card-body tele-table-padding">
                                       {/*<!-- tele-table-rowbg1 -->*/}
                                       <div className="row studylist">
                                          <div className="col-3 col-sm-2 col-md-2"><p className="profile-table-text"><small>123568 </small></p></div>
                                          <div className="col-3 col-sm-3 col-md-3">
                                             <p className="tele-table-text"><small>RABIN GHOSH </small></p>
                                          </div>

                                          <div className="col-3 col-sm-2 col-md-2">
                                             <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch"  />
                                             </div>
                                          </div>
                                          <div className="col-3 col-sm-2 col-md-2">
                                             <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch"  />
                                             </div>
                                          </div>
                                       </div>

                                       <nav aria-label="Page navigation" className="mt-3">
                                          <ul className="pagination pagination-sm justify-content-end">
                                             <li className="page-item disabled">
                                                <a className="page-link" href="# "><i className="bi bi-arrow-left"></i></a>
                                             </li>
                                             <li className="page-item disabled">
                                                <a className="page-link" href="# " tabindex="-1"><i className="bi bi-caret-left-fill"></i></a>
                                             </li>
                                             <li className="page-item"><a className="page-link" href="# ">1</a></li>
                                             <li className="page-item"><a className="page-link" href="# ">2</a></li>
                                             <li className="page-item"><a className="page-link" href="# ">3</a></li>
                                             <li className="page-item">
                                                <a className="page-link" href="#ref"><i className="bi bi-caret-right-fill"></i></a>
                                             </li>
                                             <li className="page-item">
                                                <a className="page-link" href="# "><i className="bi bi-arrow-right"></i></a>
                                             </li>
                                             <li><input type="page" className="form-control form-control-sm width60" id="pageNo" placeholder="Page" /></li>
                                             <li><button type="button" className="btn btn-tele btn-sm"><i className="bi bi-arrow-right-square"></i></button></li>
                                          </ul>
                                       </nav>
                                    </div>
                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>

                  </div>
               </div>

            </div>
         </main>

         {users.loading && <div className="spinner-border spinner-border-sm"></div>}
         {users.error && <div className="text-danger">Error loading users: {users.error.message}</div>}


      </>
   );
}
