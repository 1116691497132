import React from 'react'

export const About = () => {
    return (
        <div>
            This is an about company
            <p>product details</p>
        </div>
    )
}
