import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { history } from '_helpers';
import { PrivateRoute } from '_components';
import { Home } from 'home';
import { Login } from 'login';
import { QRCodeReport } from 'qrcodereport';
import { About } from './pages/About';

export { App };

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <div className="app-container bg-light">
            <Routes>
                <Route path="/qrcodereport" element={<QRCodeReport />} />
                <Route path="/login" element={<Login />} />

                <Route path="/" element={
                                <PrivateRoute>
                                    <Home pagename="StudyList" />
                                </PrivateRoute>
                                } />
                
                <Route path="/patient" element={
                                <PrivateRoute>
                                    <Home pagename="Patient" />
                                </PrivateRoute>
                                } />

                <Route path="/invoice" element={
                                <PrivateRoute>
                                    <Home pagename="Invoice" />
                                </PrivateRoute>
                                } />

                <Route path="/dashboard" element={
                                <PrivateRoute>
                                    <Home pagename="Dashboard" />
                                </PrivateRoute>
                                } />

                <Route path="/imageviewer" element={
                                <PrivateRoute>
                                    <Home pagename="ImageViewer" />
                                </PrivateRoute>
                                } />

                <Route path="/profile" element={
                                <PrivateRoute>
                                    <Home pagename="Profile" />
                                </PrivateRoute>
                                } />

                <Route path="/center" element={
                                <PrivateRoute>
                                    <Home pagename="Center" />
                                </PrivateRoute>
                                } />

                <Route path="/rate" element={
                                <PrivateRoute>
                                    <Home pagename="Rate" />
                                </PrivateRoute>
                                } />

                <Route path="/about" element={<About />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            
        </div>
    );
}
