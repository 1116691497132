import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '_helpers';
import axios from 'axios';

export { ImageViewer };

function ImageViewer(props) {
    const { user: authUser } = useSelector(x => x.auth);
    const { users } = useSelector(x => x.users);
    
    const [ImageViewerData, setImageViewerData] = useState([]);
    
    useEffect(() => {
        ImageViewerList();
      }, []);

      
    // only show when logged in
    if (!authUser) return null;

    function randomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
    function ImageViewerList() {
        if (!authUser) return null;

        const url = `${process.env.REACT_APP_API_URL}/invoice`;
  
        const user_key = authUser?.user_key;
        const center_key = authUser?.center_key;

        const request_id = randomNumberInRange(1000000, 9000000);
        const token = JSON.parse(localStorage.getItem('user')).token;
        const search_flag = "";
        const config = {
          headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'user_key': user_key.toString(),
            'center_key': center_key.toString(),
            'search_flag': search_flag,
            'request_id': request_id.toString()
          },
        };
    
        try {
          
          axios.get(url, config).then((response) => {
            //console.log(response.data);
            
            //alert(JSON.stringify(response.data));
            
            let updatedValue = {};
            if(response.data.length>0){
              updatedValue = response.data; 
              //const data = JSON.stringify(response.data);
              //alert(ImageViewerData);
           }

           setImageViewerData(updatedValue);
           var ss = ImageViewerData;
  
          });
        } catch (err) {
          //debugger;
          //alert('error');
            //alert(err.code);
          if (err.code === '404') {
            //setError('Object does not exist.')
            return
          }
        }
    
      }
  
      function sortClick(event, col_name)
      {
          ;
      }  

    function showMarkerArea() {
       /* if (this.imgRef.current !== null) {
          // create a marker.js MarkerArea
          const markerArea = new markerjs2.MarkerArea(document.getElementById('myimg'));
          // attach an event handler to assign annotated image back to our image element
          markerArea.addEventListener('render', event => {
            document.getElementById('myimg').src = event.dataUrl;
          });
          // launch marker.js
          markerArea.show();
        }*/
    }
    
    function showFullImage(imagePath) {
      document.getElementById('imgFull').src = imagePath;
    }  
    function saveClick()
    {
      alert(document.getElementById('imgFull').src);
      //window.location.href = document.getElementById('imgFull').src.replace('image/png', 'image/octet-stream');
      //document.getElementById('changedimg').src = document.getElementById('imgFull').src.replace('image/png', 'image/octet-stream');
    }

    const closeClick = (event) => {
        history.navigate('/home');
    };

    
    return (
        <>
            <main>
                <div className="container-fluid px-4">
                    <div className="row">
                        <div className="cpl-12 col-lg-4 col-md-4 col-sm-4 mt-2">
                            <h5 className="text-blue font-roboto font-weight700"><small>Image Viewer</small></h5>
                        </div>

                        <div className="col-6 col-lg-2 col-md-2 col-sm-2 mt-2 mb-1">
                            
                        </div>
                        <div className="col-6 col-lg-2 col-md-2 col-sm-2 mt-2 mb-1">
                            
                        </div>

                        <div className="col-6 col-lg-2 col-md-2 col-sm-2 mt-2 mb-1">
                            <button type="button" className="btn btn-secondary btn-sm btn-block text-light bi bi-save2" name="Save" onClick={saveClick}> Save</button>
                        </div>
                        <div className="col-6 col-lg-2 col-md-2 col-sm-2 mt-2 mb-1">
                            <button type="button" className="btn btn-dark btn-sm btn-block text-light bi bi-x-circle" name="Close" onClick={closeClick} > CLOSE</button>
                        </div>
                    </div>
                    <hr className="mt-0" />
                    <div className="row">
                        <div className="col-md-1 mb-2">
                          <div className="card">
                            <span style={{fontSize:'10pt'}}>CR 1/2</span>
                            <img id="img1" alt="Thumbail" style={{ maxWidth: '80%'}} src="/img/report (1).jpg" onClick={event => showFullImage('/img/report (1).jpg') } ></img>
                          </div>
                          <hr className="mt-1" />
                          <div className="card">
                            <span style={{fontSize:'10pt'}}>CR 1/2</span>
                            <img id="img2" alt="Thumbail" style={{ maxWidth: '80%' }} src="/img/report (2).jpg" onClick={event => showFullImage('/img/report (2).jpg') } ></img>
                          </div>
                        </div>
                        <div className="col-md-11 mb-2">
                            <div className="card">
                              <img id="imgFull" alt="FullImage" style={{ maxWidth: '40%' }} src="/img/report (1).jpg" onClick={() => this.showMarkerArea()} ></img>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {users.loading && <div className="spinner-border spinner-border-sm"></div>}
            {users.error && <div className="text-danger">Error loading users: {users.error.message}</div>}

        </>
    );
}
