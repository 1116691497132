import { useSelector } from 'react-redux';

export { Center };

function Center() {
   const { user: authUser } = useSelector(x => x.auth);
   const { users } = useSelector(x => x.users);

   // only show when logged in
   if (!authUser) return null;

   return (
      <>
         <main>
            <div class="container-fluid px-4">
               <div class="row">
                  <div class="col-lg-8 col-md-4 mt-3">
                     <h5 class="text-blue font-roboto font-weight700"><small>Master Details Page </small></h5>
                  </div>
                  <div class="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" class="btn btn-secondary btn-sm btn-block text-light bi bi-save2" id=""> Save</button>
                  </div>
                  <div class="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" class="btn btn-dark btn-sm btn-block text-light bi bi-save2" id=""> Update</button>
                  </div>
                  <div class="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" class="btn btn-secondary btn-sm btn-block text-light bi bi-trash3-fill" id=""> Delete</button>
                  </div>
                  <div class="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" class="btn btn-dark btn-sm btn-block text-light bi bi-x-circle" id=""> Close</button>
                  </div>
               </div>
               <hr class="mt-0" />
               <div class="row">
                  <div class="col-lg-12 col-md-12 mb-4">
                     <div class="card">
                        <div class="card-body">
                           <div class="tab-content" id="myTabContent">
                              <div class="tab-pane fade show active mt-2" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                 <div class="row p-2">
                                    <div class="col-12 col-lg-12 col-md-12">
                                       <div class="row pe-2 ps-2">
                                          <div class="col-4 col-lg-2 col-md-12">
                                             <div class="mb-2">
                                                <label htmlFor="" class="mb-1"><small><i class="fa fa-id-badge" aria-hidden="true"></i> Center ID: </small></label>
                                             </div>
                                          </div>
                                          <div class="col-8 col-lg-4 col-md-12">
                                             <div class="mb-2">
                                                <input type="text" class="form-control" placeholder="Center ID" id="" aria-describedby="" />
                                             </div>
                                          </div>
                                          <div class="col-4 col-lg-2 col-md-12">
                                             <div class="mb-2">
                                                <label htmlFor="" class="mb-1"><small><i class="fa fa-user" aria-hidden="true"></i> Center Name: </small></label>
                                             </div>
                                          </div>
                                          <div class="col-8 col-lg-4 col-md-12">
                                             <div class="mb-2">
                                                <input type="text" class="form-control" placeholder="Center Name" id="" aria-describedby="" />
                                             </div>
                                          </div>
                                       </div>
                                       <div class="row pe-2 ps-2">
                                          <div class="col-4 col-lg-2 col-md-12">
                                             <div class="mb-2">
                                                <label htmlFor="" class="mb-1"><small><i class="fa fa-phone" aria-hidden="true"></i> Phone No.: </small></label>
                                             </div>
                                          </div>
                                          <div class="col-8 col-lg-4 col-md-12">
                                             <div class="mb-2">
                                                <input type="email" class="form-control" placeholder="Phone No." id="" aria-describedby="" />
                                             </div>
                                          </div>
                                          <div class="col-4 col-lg-2 col-md-12">
                                             <div class="mb-2">
                                                <label htmlFor="" class="mb-1"><small><i class="fa fa-envelope-o" aria-hidden="true"></i> Email: </small></label>
                                             </div>
                                          </div>
                                          <div class="col-8 col-lg-4 col-md-12">
                                             <div class="mb-2">
                                                <input type="email" class="form-control" placeholder="Email" id="" aria-describedby="" />
                                             </div>
                                          </div>
                                       </div>

                                       <div class="row pe-2 ps-2">
                                          <div class="col-4 col-lg-2 col-md-12">
                                             <div class="mb-2">
                                                <label htmlFor="" class="mb-1"><small><i class="fa fa-shield" aria-hidden="true"></i> Address 1: </small></label>
                                             </div>
                                          </div>
                                          <div class="col-8 col-lg-4 col-md-12">
                                             <div class="mb-2">
                                                <textarea class="form-control" id="" placeholder="Address 1" rows="3"></textarea>
                                             </div>
                                          </div>
                                          <div class="col-4 col-lg-2 col-md-12">
                                             <div class="mb-2">
                                                <label htmlFor="" class="mb-1"><small><i class="fa fa-shield" aria-hidden="true"></i> Address 2: </small></label>
                                             </div>
                                          </div>
                                          <div class="col-8 col-lg-4 col-md-12">
                                             <div class="mb-2">
                                                <textarea class="form-control" id="" placeholder="Address 2" rows="3"></textarea>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="row pe-2 ps-2">
                                          <div class="col-4 col-lg-2 col-md-12">
                                             <div class="mb-2">
                                                <label htmlFor="" class="mb-1"><small><i class="fa fa-shield" aria-hidden="true"></i> State: </small></label>
                                             </div>
                                          </div>
                                          <div class="col-8 col-lg-4 col-md-12">
                                             <div class="mb-2">
                                                <input type="text" class="form-control" id="State" placeholder="State" />
                                             </div>
                                          </div>
                                          <div class="col-4 col-lg-2 col-md-12">
                                             <div class="mb-2">
                                                <label htmlFor="" class="mb-1"><small><i class="fa fa-shield" aria-hidden="true"></i> Country: </small></label>
                                             </div>
                                          </div>
                                          <div class="col-8 col-lg-4 col-md-12">
                                             <div class="mb-2">
                                                <input type="text" class="form-control" placeholder="Country" id="" aria-describedby="" />
                                             </div>
                                          </div>
                                       </div>
                                       <div class="row pe-2 ps-2">
                                          <div class="col-4 col-lg-2 col-md-12">
                                             <div class="mb-2">
                                                <label htmlFor="" class="mb-1"><small><i class="fa fa-th-list" aria-hidden="true"></i> Center Type: </small></label>
                                             </div>
                                          </div>
                                          <div class="col-8 col-lg-4 col-md-12">
                                             <div class="mb-2">
                                                <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                                                   <option selected>Select User Type</option>
                                                   <option value="A">Type 1</option>
                                                   <option value="B">Type 2</option>
                                                   <option value="C">Type 3</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div class="col-12 col-lg-6 col-md-6">
                                             <div class="mb-1 form-check">
                                                <input type="checkbox" class="form-check-input" id="commissionAppl" />
                                                <label class="form-check-label" htmlFor="commissionAppl"><small>Freeze </small></label>
                                             </div>
                                          </div>
                                       </div>


                                       <h5>Account Details:</h5>
                                       <hr />
                                          <div class="row pe-2 ps-2">
                                             <div class="col-4 col-lg-2 col-md-12">
                                                <div class="mb-2">
                                                   <label htmlFor="" class="mb-1"><small><i class="bi bi-bank2"></i> Bank Name: </small></label>
                                                </div>
                                             </div>
                                             <div class="col-8 col-lg-4 col-md-12">
                                                <div class="mb-2">
                                                   <input type="text" class="form-control" placeholder="Bank Name" id="" aria-describedby="" />
                                                </div>
                                             </div>
                                             <div class="col-4 col-lg-2 col-md-12">
                                                <div class="mb-2">
                                                   <label htmlFor="" class="mb-1"><small><i class="bi bi-bank2"></i> Bank Address: </small></label>
                                                </div>
                                             </div>
                                             <div class="col-8 col-lg-4 col-md-12">
                                                <div class="mb-2">
                                                   <input type="text" class="form-control" placeholder="Bank Address" id="" aria-describedby="" />
                                                </div>
                                             </div>
                                          </div>
                                          <div class="row pe-2 ps-2">
                                             <div class="col-4 col-lg-2 col-md-12">
                                                <div class="mb-2">
                                                   <label htmlFor="" class="mb-1"><small><i class="bi bi-bank2"></i> Account No.: </small></label>
                                                </div>
                                             </div>
                                             <div class="col-8 col-lg-4 col-md-12">
                                                <div class="mb-2">
                                                   <input type="text" class="form-control" placeholder=" Account No." id="" aria-describedby="" />
                                                </div>
                                             </div>
                                             <div class="col-4 col-lg-2 col-md-12">
                                                <div class="mb-2">
                                                   <label htmlFor="" class="mb-1"><small><i class="bi bi-bank2"></i> IFSC: </small></label>
                                                </div>
                                             </div>
                                             <div class="col-8 col-lg-4 col-md-12">
                                                <div class="mb-2">
                                                   <input type="text" class="form-control" placeholder="IFSC" id="" aria-describedby="" />
                                                </div>
                                             </div>
                                          </div>
                                       
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </main>

         {users.loading && <div className="spinner-border spinner-border-sm"></div>}
         {users.error && <div className="text-danger">Error loading users: {users.error.message}</div>}


      </>
   );
}
