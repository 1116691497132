import { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';
import axios from 'axios';
import { toast } from 'react-toastify';

export { Nav };

function Nav(prop) {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    const [alertData, setAlertData] = useState([]);
    const [autoCloseTime, setAutoCloseTime] = useState(5000);
    const [notificationCount, setNotificationCount] = useState(0);

    useEffect(() => {
        setNotificationCount(0);
        AutoRefresh();
      }, []);
  
    // only show nav when logged in
    if (!authUser) return null; 

    function AutoRefresh() {
        if(localStorage.getItem('pagename') !=="StudyList"){
            return null;
        }
        if (!authUser) return null;
        
        const user_key = authUser?.user_key;
        const center_key = authUser?.center_key;
        let patient_key=0;
        let report_status='';

        const dNow = new Date();
        let msg_on_last = dNow.getFullYear() + '-' +  (dNow.getMonth() + 1)  + '-' + dNow.getDate();
        
        //alert(user_key.toString() +'-' + center_key.toString()+'-' + msg_on_last);
        const url = `${process.env.REACT_APP_API_URL}/alert`;

        const request_id = randomNumberInRange(1000000, 9000000);
        const userJSON = localStorage.getItem('user');
        let validToken = true;
        if(userJSON===null){
          validToken = false;
        }
        
        if(validToken)
        {
          if(JSON.parse(userJSON)===undefined){
            validToken = false;
          }
        }
  
        if(validToken)
        {
          if(JSON.parse(userJSON).token===undefined){
            validToken = false;
          }
        }
  
        if(!validToken){
          //alert('Session expired, try to relogin.');
           //logout(); 
           //refreshExpiryToken();
          return false;
        }
        const token = JSON.parse(userJSON).token;
  
        const config = {
          headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'user_key': user_key.toString(),
            'center_key': center_key.toString(),
            'msg_on_last': msg_on_last,
            'request_id': request_id.toString()
          },
        };
        
        setAlertData([]);  

        try {
            axios.get(url, config).then((response) => {
                let updatedValue = {};
                updatedValue = response.data; 
                
                setAlertData(updatedValue); 
                
                var imgNotifyEmpty = document.getElementById('imgNotificationEmpty');
                var divNotify  = document.getElementById('divNotification');
                var divAlertsStatus  = document.getElementById('divAlertsStatus');
                let notification_count = 0;
                divNotify.title='';
                if(updatedValue !== null){
                    if(updatedValue.length > 0){
                        notification_count = updatedValue[0].notification_count;
                        if(updatedValue.length === 1){
                            divNotify.title= updatedValue[0].msg_desc + ', Ref: ' + updatedValue[0].patient_id + ' ' + updatedValue[0].patient_name + ', Status: ' + updatedValue[0].report_status;
                        }
                        //notifyInfo('New Alerts : ' + updatedValue[0].notification_count);
                    }
                    //const template = getReportContent(0, updatedValue);
                    
                }

                setNotificationCount(notification_count);

                if(notification_count > 0){
                    divAlertsStatus.innerHTML = '';
                    imgNotifyEmpty.style.display='none';
                    divNotify.style.display='';
                }
                else{
                    divAlertsStatus.innerHTML = 'No alert found!';
                    imgNotifyEmpty.style.display='';
                    divNotify.style.display='none';
                }

                if(authUser?.user_type === 'D'){
                    setTimeout(function() {
                        AutoRefresh();
                    }, 1000 * (60*1));
                }
            })
           .catch(error => {
            //alert(error);
            if(error.response.data.message==='Unauthorized')
            {
              ;//alert('Unauthorized session, try to relogin.');
            }
            else{
            
              ;//alert(error.response.data.message);
            }
           });
    
          } catch (err) {
            //alert('error');
              //alert(err.code);
            if (err.code === '404') {
              //setError('Object does not exist.')
              return
            }
          }
    }

    const openAlert = (event, param, action_tag) => {
      event.preventDefault();
      
      if(param === 'OPEN'){
        AutoRefresh();
      }

      let modal = document.querySelector("#divAlertModal");
      modal.classList.toggle("closed");
    };

    function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const notifyInfo = (msgValue) => {
        setTimeout(function () {
            toast.info(msgValue, {
              position: "top-right"
            });
          }, 100);
      };

    return (
        <>
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-gradient-1">
            
            <a className="navbar-brand ps-3 nav-header" href="index.html">Teleradiology</a>
            
            <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" onClick={prop.showHideMenu}><i className="fa fa-bars" aria-hidden="true"></i></button>
            
            <ul className="navbar-nav ms-auto ms-md-0 me-lg-4 margin-auto">
                <img id="imgNotificationEmpty" src="../../img/ringing.png" height="25px" width="25px" alt="" className="mt-2 me-1" style={{cursor: "pointer"}} onClick={event => openAlert(event, 'OPEN')} />
                <div id="divNotification" style={{display:"none"}}>
                    <img id="imgNotification" src="../../img/alarm-bell.png" height="25px" width="25px" alt="" className="mt-2 me-1" style={{cursor: "pointer"}} onClick={event => openAlert(event, 'OPEN')} />
                    <span className="badge notification-alert-badge rounded-pill badge-primary">{notificationCount}</span>
                    
                </div>
                <span style={{width:"30px"}}>&nbsp;</span>
                <img id="imgProfile" src="../../img/profile-pink.png" height="25px" width="25px" alt="" className="mt-2 me-1"  />
                
                <div className="text-white mt-2 font-roboto font-weight400 profile_text">{authUser?.user_title} {authUser?.user_name}</div>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" name="navbarDropdown" href=" " role="button" data-bs-toggle="dropdown" aria-expanded="false"> </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        {/*<li><a className="dropdown-item" href="/">Home</a></li>
                        <li><a className="dropdown-item" href="/about">About</a></li>
                        
                        <li><a className="dropdown-item" href="/profile">Profile</a></li>*/}
                        <li><hr className="dropdown-divider" /></li>
                        <li><a className="dropdown-item" href="#!"  onClick={logout}>Logout</a></li>
                    </ul>
                </li>
            </ul>
        </nav>
        
        <div id="divAlertModal" className="modal closed">
          {/*<!--Modal content -->*/}
          <div className="alert-modal-content">
              <div className="modal-header pt-2">
                  <span className="close" onClick={event => openAlert(event, 'CLOSE')}>&times;</span>
                  <h6 className="mb-0"><i className="bi bi-clipboard-check-fill"></i> Alerts</h6>
              </div>
              
              <div className="modal-body" style={{overflow:"auto", padding:"0rem"}}>
                <div id="divAlertsStatus"></div>

<div className="container">
<div className="ks-page-content">
    <div className="ks-page-content-body">
        <div className="ks-messenger">            
            <div className="ks-messages ks-messenger__messages">
                <div className="ks-body ks-scrollable jspScrollable">
                    <div className="jspContainer">
                        <div className="jspPane">
                            <ul className="ks-items"> {/* ks-item ks-from ks-unread  <li className="ks-separator">Today</li> */}
                              {Object.keys(alertData).map((data, i) => (
                                <li key={i} className="ks-item ks-self">
                                    <span className="ks-avatar ks-offline">
                                        {(alertData[i].sender_type === 'D' ?  
                                            <img src="../../img/doctor_icon.png" width="36" height="36" className="rounded-circle"></img>
                                            : 
                                            (alertData[i].sender_type === 'A' ?  
                                                <img src="../../img/admin_icon.png" width="36" height="36" className="rounded-circle"></img>
                                                : 
                                                <img src="../../img/user_icon.png" width="36" height="36" className="rounded-circle"></img>
                                            )
                                        )}
                                    </span>
                                    <div className="ks-body">
                                        <div className="ks-header">
                                            <span className="ks-name">
                                                {(authUser?.user_type === 'U' ?  
                                                    (alertData[i].sender_type === 'D' ?  
                                                        <b>Doctor</b>
                                                        : <b>{alertData[i].sender_name}</b>
                                                    )
                                                    : 
                                                    (authUser?.user_type === 'D' || authUser?.user_type === 'R' ?  
                                                        (alertData[i].sender_type === 'U' ?  
                                                            <b>Center User</b>
                                                            : <b>{alertData[i].sender_name}</b>
                                                        )
                                                        : 
                                                        (alertData[i].sender_type === 'D' ?  
                                                            <b>{alertData[i].sender_title} {alertData[i].sender_name} ({alertData[i].user_id})</b>
                                                            : <b>{alertData[i].sender_name}</b>
                                                        )
                                                    )
                                                )}
                                                &nbsp;Ref: {alertData[i].patient_id} - {alertData[i].patient_name} (Status: {alertData[i].report_status})
                                            </span>
                                            <span className="ks-datetime">{alertData[i].msg_date} {alertData[i].msg_time}</span>
                                        </div>
                                        <div className="ks-message">{alertData[i].msg_desc}</div>
                                    </div>
                                </li>
                              ))}
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

                        
                  
              </div>

              <div className="modal-footer pt-2">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-6"><button type="button"
                            className="btn btn-secondary btn-sm btn-block bi bi-x-square" id="btnAlertClose" onClick={event => openAlert(event, 'CLOSE')}> CLOSE </button></div>
                </div>
              </div>

          </div>
        </div>
        
        </>
    );
}