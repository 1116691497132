import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export { Rate };

function Rate() {
   const { user: authUser } = useSelector(x => x.auth);
   const { users } = useSelector(x => x.users);

   useEffect(() => {
      
      /*
            $(document).ready(function () {
      
               var logAllEvents = true;
      
      
               //Basic editor with no navigation
               var simpleEditor = new SimpleTableCellEditor("simpleEditableTable", {navigation:false});
               simpleEditor.SetEditableClass("editMe");
      
               $('#simpleEditableTable').on("cell:edited", function (event) {
                   console.log(`'${event.oldValue}' changed to '${event.newValue}'`);
               });
      
      
               //Advanced editor
               var advancedEditor = new SimpleTableCellEditor("advancedEditableTable");
               advancedEditor.SetEditableClass("numericEdit", { validation: $.isNumeric });
               advancedEditor.SetEditableClass("numericTimes100", { validation: $.isNumeric, formatter: (val) => { return val * 100; } });
               advancedEditor.SetEditableClass("customRenderer", {
                   internals: {
                       renderEditor: (elem, oldVal) => {
                           $(elem).html(`<select>
                                           <option>Type 1</option>
                                           <option>Type 2</option>
                                           <option>Type 3</option>
                                           <option>Type 4</option>
                                       </select>`);
      
                           $("select option").filter(function () {
                               return $(this).val() == oldVal;
                           }).prop('selected', true);
      
                       },
                       extractEditorValue: (elem) => { return $(elem).find('select').val(); },
      
                   }
               });
      
      
               if (logAllEvents) {
      
                   $('table').on("cell:onEditEnter", function (event) {
                       console.log('onEditEnter event');
                   });
      
                   $('table').on("cell:onEditEntered", function (event) {
                       console.log('onEditEntered event');
                   });
      
                   $('table').on("cell:onEditExit", function (event) {
                       console.log('onEditExit event');
                   });
      
                   $('table').on("cell:onEditExited", function (event) {
                       console.log('onEditExited event');
                   });
               }
      
      
      
           });
           */

   }, []);

   // only show when logged in
   if (!authUser) return null;

   let divStyle1 = {
      width: "20%",
   }

   let divStyle2 = {
      width: "20%",
      padding: "4px 2px",
   }
   let divStyle3 = {
      width: "100%",
   }

   return (
      <>
         <main>
            <div class="container-fluid px-4">
               <div class="row">
                  <div class="col-lg-8 col-md-4 mt-3">
                     <h5 class="text-blue font-roboto font-weight700"><small>Master Details Page </small></h5>
                  </div>
                  <div class="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" class="btn btn-secondary btn-sm btn-block text-light bi bi-save2" id=""> Save</button>
                  </div>
                  <div class="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" class="btn btn-dark btn-sm btn-block text-light bi bi-save2" id=""> Update</button>
                  </div>
                  <div class="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" class="btn btn-secondary btn-sm btn-block text-light bi bi-trash3-fill" id=""> Delete</button>
                  </div>
                  <div class="col-6 col-lg-1 col-md-2 mt-2 mb-1">
                     <button type="button" class="btn btn-dark btn-sm btn-block text-light bi bi-x-circle" id=""> Close</button>
                  </div>

               </div>

               <hr class="mt-0" />

               <div class="row">
                  <div class="col-lg-12 col-md-12 mb-4">
                     <div class="card">
                        <div class="card-body">


                           <table id="advancedEditableTable" class="table table-striped" style={divStyle3}>
                              <thead>
                                 <tr>
                                    <th style={divStyle1}>Center Name</th>
                                    <th style={divStyle1}>Center Type</th>
                                    <th style={divStyle1}>Phone</th>
                                    <th style={divStyle1}>Email</th>
                                    <th style={divStyle1}>Address</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <td style={divStyle2} class="numericEdit">Center 1</td>
                                    <td style={divStyle2} class="customRenderer">Type 2</td>
                                    <td style={divStyle2} class="numericEdit">8981367705</td>
                                    <td style={divStyle2} class="numericEdit">center@gmail.com</td>
                                    <td style={divStyle2} class="numericEdit">12C, N,. S. C. Bose Road</td>
                                 </tr>

                              </tbody>
                           </table>
                        </div>
                     </div>

                  </div>
               </div>

            </div>
         </main>
         {users.loading && <div className="spinner-border spinner-border-sm"></div>}
         {users.error && <div className="text-danger">Error loading users: {users.error.message}</div>}


      </>
   );
}
